import ENV from "./ENV";

interface IAPIConfig {
    [k: string]: {
        URL: string | Function | any,
        METHOD: "get" | "post" | "delete" | "put"
    }
}

const APIConfig: IAPIConfig = {
    //meta start
    GET_FEED: {
        URL: ENV.AIR_TABLE_API,
        // URL: ENV.API_URL + "/feed",
        METHOD: "get"
    },
    //meta end
}

export default APIConfig;
