import {
    DASHBOARD,
} from "../../constants/RoutesConfig";

const Dashboard = () => {
    return DASHBOARD;
}

const RouteConfigService = {
    Dashboard,
}

export default RouteConfigService;
