import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {
    DASHBOARD,
} from "../constants/RoutesConfig";
import AppLayout from "../layouts/app-layout/AppLayout";
import DashboardScreen from "../screens/dashboard/DashboardScreen";

export interface NavigatorProps {

}

const Navigator = (props: NavigatorProps) => {

    return (
        <Routes>
            <Route element={<AppLayout/>}>
                <Route
                    index
                    element={
                        <DashboardScreen/>
                    }
                />
                <Route
                    path={DASHBOARD}
                    element={
                        <DashboardScreen/>
                    }
                />
            </Route>
            <Route path="*" element={<Navigate to={DASHBOARD}/>}/>
        </Routes>
    )
};

export default Navigator;



