import "./pageLoaderComponent.scss";

import React from 'react';
import { ImageConfig } from "../../../constants";

interface PageLoaderComponentProps {

}

const PageLoaderComponent = (props: PageLoaderComponentProps) => {
    return (
        <div className="page-loading">
            <img src={ImageConfig.Loader} alt={"page-loader"}/>
        </div>
    )
};

export default PageLoaderComponent;
