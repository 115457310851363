import { combineReducers } from "redux";
import StaticDataReducer, {IStaticDataReducerState} from "./static-data.reducer";

export interface IRootReducerState {
    staticData: IStaticDataReducerState,
}

const rootReducer = combineReducers({
    staticData: StaticDataReducer,
});

export default rootReducer;
