import './App.scss';
import Navigator from "./navigation/navigator";
import AlertComponent from "./shared/components/alert/alertComponent";
import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import moment from "moment";

interface AppProps {
}

const App = (props: AppProps) => {

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        searchParams.set("currentTimeStamp", moment().unix().toString()); // to make app gets recent copy of website always ( Can't hard refresh in TV browser )
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    return (
        <div className="app">
            <Navigator/>
            <AlertComponent/>
        </div>
    );
}

export default App;
