import "./DashboardScreen.scss";
import { IAPIResponseType } from "../../shared/models/api.model";
import { useCallback, useEffect, useState } from "react";
import { CommonService } from "../../shared/services";
import { IPost } from "../../shared/models/feed.model";
import StatusComponentComponent from "../../shared/components/status-component/StatusComponentComponent";
import PageLoaderComponent from "../../shared/components/page-loader/pageLoaderComponent";
import FeedSlideShowComponent from "../../shared/components/feed-slide-show/FeedSlideShowComponent";

interface DashboardScreenProps {

}

const DashboardScreen = (props: DashboardScreenProps) => {

    const [feed, setFeed] = useState<IPost[]>([]);
    const [isFeedLoading, setIsFeedLoading] = useState<boolean>(false);
    const [isFeedLoaded, setIsFeedLoaded] = useState<boolean>(false);
    const [isFeedLoadingFailed, setIsFeedLoadingFailed] = useState<boolean>(false);

    const parseFeed = useCallback((response: any) => {
        const normalizedData: IPost[] = [];
        response.records.forEach((record: any) => {
            const fields = record.fields;
            if (fields.title) {
                normalizedData.push({
                    ...fields,
                    assetPath: (fields?.assetPath?.length > 0 ? fields.assetPath[0]?.url : null),
                    animation: fields?.animation || "slide-left",
                    type: fields?.type || "image",
                    duration: fields?.duration || 5,
                    template: fields?.template || "custom",
                });
            }
        });
        // normalizedData.push({
        //     title: "",
        //     duration: 30,
        //     hideInfo: false,
        //     animation: "slide-left",
        //     description: "",
        //     type: "embed",
        //     assetPath: "https://airtable.com/embed/shrb1FtM37mBUcelL?backgroundColor=red&viewControls=on"
        // });
        return normalizedData;
    }, []);

    const fetchFeed = useCallback((showLoading: boolean = true) => {
        console.log('fetchFeed');
        if (showLoading) {
            setIsFeedLoading(true);
        }
        CommonService._feed.FeedListAPICall()
            .then((response: IAPIResponseType<IPost[]>) => {
                console.log("api response", response);
                const feed = parseFeed(response);
                setTimeout(() => {
                    setFeed(feed);
                    setIsFeedLoading(false);
                    setIsFeedLoaded(true);
                    setIsFeedLoadingFailed(false);
                }, 1000);
            }).catch((error: any) => {
            console.log("api error", error);
            setFeed([]);
            setIsFeedLoading(false);
            setIsFeedLoaded(false);
            setIsFeedLoadingFailed(true);
        });
    }, [parseFeed]);

    useEffect(() => {
        fetchFeed();
        setInterval(() => {
            fetchFeed(false);
        }, 60 * 60 * 1000); // refresh the list for every one hour;
    }, [fetchFeed]);

    return (
        <div className={'DashboardScreen'}>
            {
                isFeedLoading && <PageLoaderComponent/>
            }
            {
                isFeedLoadingFailed && <StatusComponentComponent title={"Failed to fetch feed"}/>
            }
            {
                isFeedLoaded && <>
                    {
                        feed.length === 0 && <StatusComponentComponent title={"Feed is empty"}/>
                    }
                    {
                        feed.length > 0 && <FeedSlideShowComponent feed={feed}/>
                    }
                </>
            }
        </div>
    );

};

export default DashboardScreen;
