import "./SlideComponent.scss";
import { IPost } from "../../models/feed.model";
import { useEffect, useState } from "react";
import PageLoaderComponent from "../page-loader/pageLoaderComponent";

interface SlideComponentProps {
    slide: IPost;
}

const SlideComponent = (props: SlideComponentProps) => {

    const [slide, setSlide] = useState<IPost | undefined>(props.slide);
    const [isFrameLoaded, setIsFrameLoaded] = useState<boolean>(false);

    useEffect(() => {
        setSlide(undefined);
        setTimeout(() => {
            setSlide(props.slide);
        }, 10);
    }, [props.slide]);

    useEffect(() => {
        setIsFrameLoaded(false);
    }, [slide]);

    return (
        <>
            {
                slide && <>
                    {
                        slide.type === "image" &&
                        <>
                            {
                                slide.template === "custom" && <div className={`slide-component ${slide.animation}`}
                                                                    style={{backgroundImage: "url(" + slide.assetPath + ")"}}>
                                    {
                                        !slide.hideInfo && <>
                                            <div
                                                className={'slide-meta'}>
                                                <div
                                                    className={'slide-title'}>
                                                    {slide.title}
                                                </div>
                                                <div
                                                    className={'slide-description'}>
                                                    {slide.description}
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                            {
                                slide.template === "birthday" &&
                                <div className={`slide-component ${slide.animation} birthday`}>
                                    <div className={'birthday-content'}>
                                        <div className={'birthday-profile-image'}>
                                            <img src={slide.assetPath} alt=""/>
                                        </div>
                                        <div className="birthday-title">
                                            {slide.title}
                                        </div>
                                        <div className="birthday-subtitle">
                                            {slide.description}
                                        </div>
                                        <div className="birthday-wishes">
                                            “{
                                                slide.customMessage ? slide.customMessage : "May you be gifted with life’s biggest joys and never-ending bliss. After all,you yourself are a gift to earth, so you deserve the best. Happy birthday."
                                            }”
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                slide.template === "new-employee" &&
                                <div className={`slide-component ${slide.animation} new-employee`}>
                                    <div className={'new-employee-content'}>
                                        <div className={'new-employee-profile-image'}>
                                            <img src={slide.assetPath} alt=""/>
                                        </div>
                                        <div className="new-employee-title">
                                            {slide.title}
                                        </div>
                                        <div className="new-employee-subtitle">
                                            {slide.description}
                                        </div>
                                        <div className="new-employee-wishes">
                                            “{
                                            slide.customMessage ? slide.customMessage : "Welcome! We hope you’ll always feel free to speak up, share your thoughts and challenge us. We’re all going to grow together here, and we look forward to it!"
                                        }”
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {
                        slide.type === "iframe" &&
                        <>
                            <iframe className="airtable-embed"
                                    title={slide.title}
                                    src={slide.description}
                                    onLoad={() => {
                                        setIsFrameLoaded(true);
                                    }}
                                    frameBorder="0" width="100%" height="100%"
                                    style={{background: "transparent", border: "1px solid #ccc"}}></iframe>
                            {!isFrameLoaded && <PageLoaderComponent/>}
                        </>
                    }
                    {
                        slide.type === "video" &&
                        <div
                            className={`slide-component ${slide.animation} video`}>
                            <video width="100%" height="100%" autoPlay muted loop={true}>
                                <source src={slide.assetPath} type="video/mp4"/>
                            </video>
                        </div>
                    }
                </>
            }
        </>
    );

};

export default SlideComponent;
