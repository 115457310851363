import { ApiService } from "../index";
import { APIConfig, ENV } from "../../../constants";

const FeedListAPICall = () => {
    // @ts-ignore
    return ApiService[APIConfig.GET_FEED.METHOD](APIConfig.GET_FEED.URL, {}, {
        'Authorization': 'Bearer ' + ENV.AIR_TABLE_API_KEY
    });
}

const FeedService = {
    FeedListAPICall
}

export default FeedService;
