import "./FeedSlideShowComponent.scss";
import { useEffect, useState } from "react";
import SlideComponent from "../slide/SlideComponent";
import { IPost } from "../../models/feed.model";

interface FeedSlideShowComponentProps {
    feed: IPost[];
}

const FeedSlideShowComponent = (props: FeedSlideShowComponentProps) => {

    const {feed} = props;
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number | undefined>(undefined);

    useEffect(() => {
        setCurrentSlideIndex(0);
    }, [feed]);

    useEffect(() => {
        if (currentSlideIndex !== undefined && currentSlideIndex > -1) {
            const currentSlide = feed[currentSlideIndex];
            setTimeout(() => {
                let nextSlideIndex = currentSlideIndex + 1;
                const nextSlide = feed[nextSlideIndex];
                if (!nextSlide) {
                    nextSlideIndex = 0;
                }
                setCurrentSlideIndex(nextSlideIndex);
            }, ((currentSlide.duration) * 1000));
        }
    }, [currentSlideIndex, feed]);

    return (
        <div className={'feed-slide-show-component'}>
            {
                (currentSlideIndex !== undefined && currentSlideIndex > -1) && <SlideComponent slide={feed[currentSlideIndex]}/>
            }
        </div>
    );

};

export default FeedSlideShowComponent;
